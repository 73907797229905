// https://docs.bugsnag.com/platforms/javascript/legacy/react/customizing-error-reports/#groupinghash
// To get better grouping for Dox Analytics validation error events
// we base this on the url pathname without any identifiers like ids / uuids
import _isString from 'lodash/isString';

export default function bugsnagErrorGroupingByPath({ namespace = 'custom-group', noMatchLabel = 'unknown', pathname }) {
  const defaultGrouping = `${namespace}-${noMatchLabel}`;

  if (!_isString(pathname)) {
    return defaultGrouping;
  }

  const normalizedPath = pathname.split('/').filter((val) => {
    return (val && /^[a-zA-Z-_]+$/.test(val));
  }).join('-') || '';

  if (normalizedPath) {
    return `${namespace}-${normalizedPath}`;
  }
  return defaultGrouping;
}