import DoxAnalytics from '@dox/analytics';
import bugsnagErrorGroupingByPath from './bugsnag/bugsnag_error_grouping_by_path';

const productionEnv = process.env.RAILS_ENV === 'production';

const doxAnalytics = window.doxAnalytics = new DoxAnalytics();

function reportToBugsnag(error, errorData = {}) {
  function retry() {
    reportToBugsnag(error, errorData);
  }

  if (typeof bugsnag === "function") {
    const { version, type, properties, validationPayload, validationErrors } = errorData;

    bugsnag(process.env.BUGSNAG_API_KEY).notify(error, {
      beforeSend: function (report) {
        report.app.doxAnalyticsVersion = version;

        report.groupingHash = bugsnagErrorGroupingByPath({
          namespace: 'dox-analytics-validation',
          pathname: window.location.pathname
        });
      },
      metaData: {
        event: {
          type,
          properties
        },
        validator: {
          payload: validationPayload,
          errors: validationErrors,
        }
      }
    });
  } else {
    setTimeout(retry, 250);
  }
}

window.initAnalytics = ({ release } = {}, callback) => {
  doxAnalytics.init({
    application: "doximity/doximity",
    release,
    directReferrerDomain: process.env.DOX_ANALYTICS_DOMAIN,
    errorHandler: function(error, errorData) {
      const reportErrors = (window.ENABLE_ANALYTICS_ERROR_REPORTING && (typeof process.env.BUGSNAG_API_KEY === 'string'));
      if (reportErrors) {
        reportToBugsnag(error, errorData);
      }
    },
  });

  if (callback) {
    callback();
  }
}
